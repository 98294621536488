import React from 'react';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { Link } from 'gatsby';

import { StaticImage } from 'gatsby-plugin-image';

import '../../generic-page.scss';
import { Col, Row, Table } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';
import { HSRRelicSet } from '../../../modules/hsr/common/components/hsr-relic-set';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const HSRGuidesEarlyGearingPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="hsr">
      <ul className="breadcrumb">
        <li>
          <Link to="/star-rail/">Honkai: Star Rail</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/star-rail/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Early gearing tips</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/starrail/categories/category_earlygear.png"
            alt="Early gearing"
          />
        </div>
        <div className="page-details">
          <h1>Honkai: Star Rail early gearing</h1>
          <h2>
            Not sure what to do with your Relics in the early game? Check out
            the guide!
          </h2>
          <p>
            Last updated: <strong>24/01/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <div className="page-content">
        <SectionHeader title="Foreword" />
        <p>
          To learn about the Relic system in Honkai: Star Rail we suggest
          checking our other guide first, because this one will skip the
          explanation and instead focus on other aspects - what you should do
          with your Relics, when to farm them and other things, etc. Here's the
          introduction guide to Relics:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Relics - introduction"
            link="/star-rail/guides/relics"
            image={
              <StaticImage
                src="../../../images/starrail/categories/category_relicinfo.png"
                alt="Relics"
              />
            }
          />
        </Row>
        <SectionHeader title="Priorities" />
        <p>
          Usually in a lot of games, gear sets have a huge impact on your
          character's performance. While this is also true for Honkai: Star
          Rail, in the early game,{' '}
          <strong>
            you actually ignore the sets and focus on the Main stat instead
          </strong>
          . Why? Let's check an example Relic piece.
        </p>
        <StaticImage
          src="../../../images/starrail/generic/relic.jpg"
          alt="Guide"
        />
        <p>
          The first stat line marked with yellow color is the{' '}
          <strong>Main stat</strong>. As you can see you can get 43.2% ATK just
          from one piece of Relic - which is massive. Obviously in the early
          game, you won't be able to obtain{' '}
          <span className="hsr-rar rar-5">5★ Relics</span> and will have use
          lower rarity ones (that have smaller values).
        </p>
        <p>
          The <strong>substats</strong> - white lines - are generated randomly
          for each Relic piece and their values also increases while you level
          up an Relic. Still, compared to the Main stat that always increases at
          a set rate, the substats not only gain a random amount of stats, but
          actually you need to hit the right stat too. So it's a double RNG
          every time the Relic is upgraded by 3 levels.
        </p>
        <p>Now let's look at the bonuses a set gives:</p>
        <Row xs={1} xxl={2} className="relic-row">
          <Col>
            <HSRRelicSet name="Musketeer of Wild Wheat" />
          </Col>
        </Row>
        <p>
          As you can see, for equipping 2 Relics from the Musketeer set, you
          will only obtain 12% ATK - and a +9 upgraded{' '}
          <span className="hsr-rar rar-3">3★ Relic</span> already gives you
          17.2% ATK alone just from the Main stat.
        </p>
        <p>
          Keep in mind that Head and Hands gear pieces always roll the same Main
          Stat (Flat HP and Flat ATK), so you can consider those your flex
          pieces that make it easier to complete the sets.
        </p>
        <p>
          Overall, as you progress, here's how you will rate your gear and use
          it:
        </p>
        <ol>
          <li>
            <strong>
              Initially, just look for Relics with the right Main stat for the
              character and equip them{' '}
            </strong>
            - that's what will only interest you in the first few days,
          </li>
          <li>
            Once your characters have Relics with the Main stat you want and you
            upgraded them a bit, it's{' '}
            <strong>
              time to look for Relics that have both the Main stat you need and
              that belong to the set you want
            </strong>
            ,
          </li>
          <ul>
            <li>
              Focus first on completing 2-piece bonuses, before moving to
              4-piece ones,
            </li>
            <li>This is the mid-game of gearing,</li>
          </ul>
          <li>
            Once your characters have Relics with both the Main stat and Set you
            need, you start searching for items that on top of that have
            substats you desire.
          </li>
        </ol>
        <p>
          On top of the{' '}
          <strong>Main Stat &gt; Set &gt; Substats priority order</strong>, you
          also have to worry about the rarity of the Relic.{' '}
          <strong>Higher rarity means better Main and Substats</strong>, but
          also the cost of upgrading them increases drastically.
        </p>
        <p>
          That's why in the early game, you should be careful with upgrading.
          Here's the general rule of thumb:
        </p>
        <ul>
          <li>
            <span className="hsr-rar rar-2">2★ Relics</span> (caps at +6) you
            can equip, but don't bother with upgrading them,
          </li>
          <li>
            <span className="hsr-rar rar-3">3★ Relics</span> (caps at +9) you
            can upgrade to +6,
          </li>
          <li>
            <span className="hsr-rar rar-4">4★ Relics</span> (caps at +12) you
            can upgrade to +6/9,
          </li>
          <li>
            <span className="hsr-rar rar-5">5★ Relics</span> (caps at +15) you
            can upgrade to +9 and stop there - the cost to go to +12 and 15 is
            simply too high. It's only worth to go past that if the piece has
            great substats that also rolled well till that point.
          </li>
        </ul>
        <p>Below's video explains the gearing priority in more details.</p>
        <Row className="video-row">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="w2qpfgJV0n8" />
          </Col>
        </Row>
        <SectionHeader title="Gear farming" />
        <StaticImage
          src="../../../images/starrail/generic/corrosion.jpg"
          alt="Guide"
        />
        <p>
          Considering the importance of the rarity we{' '}
          <strong>
            suggest to avoid spending any stamina on farming Relics until you've
            increased your Equilibrium Level high enough
          </strong>
          . Initially in the Cavern of Corrosion you will be only able to farm{' '}
          <span className="hsr-rar rar-3">3★ Relics</span> with a chance to get{' '}
          <span className="hsr-rar rar-4">4★ Relics</span>. Only once you reach
          Difficulty 3 of the Caverns you will have a chance to drop{' '}
          <span className="hsr-rar rar-5">5★ Relics</span> which goes up to 100%
          drop on Difficulty 4 - and you will need a few weeks of playing to
          reach that point.
        </p>
        <p>
          But do not worry! You will get enough{' '}
          <span className="hsr-rar rar-3">3★ Relics</span> and{' '}
          <span className="hsr-rar rar-4">4★ Relics</span> from the Main Story
          rewards, treasure chests and leveling rewards and this will easily
          allow you to progress.
        </p>
        <p>So the TL;DR is that:</p>
        <ul>
          <li>
            Below TL 40 focus on farming Ascension/Traces materials and
            Character/Cone EXP,
          </li>
          <li>
            Once you reach TL 40, start farming CoC and SU as you've just
            unlocked Gold rarity.
          </li>
        </ul>
        <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
        <SectionHeader title="Who wants what?" />
        <p>
          Overall, each character has a preference when it comes to both Sets
          and Main stats, but in the early game you can't be picky. So we will
          group them in roughly 4 categories:
        </p>
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th>Category</th>
              <th>Main stat</th>
              <th>Notes</th>
              <th>Example Characters</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <strong>Classical DPS</strong>
              </td>
              <td>ATK %, Ele DMG %</td>
              <td>
                The classical DPS (damage dealer) want the holy trifecta of
                stats: ATK%, Crit Rate and Crit DMG. Still, in the early game
                reaching enough Crit to make the investment worth is simply
                impossible, so instead you will focus only on ATK% and Ele DMG %
                wherever possible.
              </td>
              <td>Physical MC, Dan Heng, Seele, Jingliu, Herta</td>
            </tr>
            <tr>
              <td>
                <strong>Break DPS</strong>
              </td>
              <td>ATK %, Break Effect %</td>
              <td>
                Break DPS rely on breaking enemies to deal massive amounts of
                damage, but if they can't break - they are useless. So you
                always need to bring them against enemies with matching
                weakness. Since Crit doesn't have any impact on the Break
                damage, going for ATK% and Break Effect % is the way for them.
              </td>
              <td>Fire MC, Sushang, Xueyi, Luka</td>
            </tr>
            <tr>
              <td>
                <strong>Buffer</strong>
              </td>
              <td>Speed</td>
              <td>
                With the way the combat system works in Honkai: Star Rail, Speed
                is king for everyone, but especially for buffers. As it allows
                them to keep their buffs up for a longer period of time and
                generate more Skill Points for the team (or stay SP Neutral).
                Still, don't forget to give them some defensive stats (HP% and
                DEF%) as a dead character can't support the team.
              </td>
              <td>Bronya, Tingyun, Asta, Yukong</td>
            </tr>
            <tr>
              <td>
                <strong>Healer</strong>
              </td>
              <td>HP, Speed</td>
              <td>
                Since the amount of healing a Healer does scaled with the their
                own HP (in the most cases - Luocha is an exception as his heals
                scale with ATK instead), it's a no-brainer to just focus on that
                stat.
              </td>
              <td>Natasha, Bailu, Lynx, Huohuo</td>
            </tr>
            <tr>
              <td>
                <strong>Shielder</strong>
              </td>
              <td>DEF, Speed</td>
              <td>
                While Healers scale with their own HP, Shielders scale with
                their own DEF. So just stack the DEF stat and see your Shielding
                numbers go up.
              </td>
              <td>March 7th, Gepard</td>
            </tr>
          </tbody>
        </Table>
        <h5>Detailed build information</h5>
        <p>
          To find out the best Relics and what stats to focus on every
          character, check their Profiles!
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      </div>
    </DashboardLayout>
  );
};

export default HSRGuidesEarlyGearingPage;

export const Head: React.FC = () => (
  <Seo
    title="Early gearing | Honkai: Star Rail | Prydwen Institute"
    description="Not sure what to do with your Relics in the early game? Check out the guide!"
  />
);
